@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.collectionSlideOut {
  height: calc(100vh - 160px);
}

/* Signature Fonts */
.Allison {
  font-family: 'Allison' !important;
  font-size: 5rem;
  line-height: 1;
}
.DancingScript {
  font-family: 'DancingScript' !important;
  font-size: 5rem;
  line-height: 1;
}
.GreatVibes {
  font-family: 'GreatVibes' !important;
  font-size: 5rem;
  line-height: 1;
}
.HerrVonMuellerhoff {
  font-family: 'HerrVonMuellerhoff' !important;
  font-size: 5rem;
  line-height: 1;
}
.OoohBaby {
  font-family: 'OoohBaby' !important;
  font-size: 5rem;
  line-height: 1;
}
.AmalfiCoast {
  font-family: 'AmalfiCoast' !important;
  font-size: 2rem;
  line-height: 1;
}
.AutographySignature {
  font-family: 'AutographySignature' !important;
  font-size: 4rem;
  line-height: 1;
}
.Bayshore {
  font-family: 'Bayshore' !important;
  font-size: 5rem;
  line-height: 1;
}
.Blackstone {
  font-family: 'Blackstone' !important;
  font-size: 4rem;
  line-height: 1;
}
.GeorgiaScript {
  font-family: 'GeorgiaScript' !important;
  font-size: 5rem;
  line-height: 1;
}
.Haystack {
  font-family: 'Haystack' !important;
  font-size: 5rem;
  line-height: 1;
}
.TheImpressionist {
  font-family: 'TheImpressionist' !important;
  font-size: 3rem;
  line-height: 2;
}


@layer base {
  @font-face {
    font-family: 'Allison';
    src: url(../src/fonts/Allison-Regular.ttf);
  }
  @font-face {
    font-family: 'DancingScript';
    src: url(../src/fonts/DancingScript-Regular.ttf);
  }
  @font-face {
    font-family: 'GreatVibes';
    src: url(../src/fonts/GreatVibes-Regular.ttf);
  }
  @font-face {
    font-family: 'HerrVonMuellerhoff';
    src: url(../src/fonts/HerrVonMuellerhoff-Regular.ttf);
  }
  @font-face {
    font-family: 'OoohBaby';
    src: url(../src/fonts/OoohBaby-Regular.ttf);
  }
  @font-face {
    font-family: 'AmalfiCoast';
    src: url(../src/fonts/AmalfiCoast.ttf);
  }
  @font-face {
    font-family: 'AutographySignature';
    src: url(../src/fonts/AutographySignature.ttf);
  }
  @font-face {
    font-family: 'Bayshore';
    src: url(../src/fonts/Bayshore.ttf);
  }
  @font-face {
    font-family: 'Blackstone';
    src: url(../src/fonts/Blackstone.ttf);
  }
  @font-face {
    font-family: 'GeorgiaScript';
    src: url(../src/fonts/GeorgiaScript.ttf);
  }
  @font-face {
    font-family: 'Haystack';
    src: url(../src/fonts/Haystack.ttf);
  }
  @font-face {
    font-family: 'TheImpressionist';
    src: url(../src/fonts/TheImpressionist.ttf);
  }

  html {
    font-family: "Clash";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

@layer components {
  .card-title {
    @apply text-xl font-semibold leading-6;
  }
  .card-description {
    @apply text-sm font-normal leading-6;
  }
}

/* Highlight JS Styles */
.hljs-tag {
  @apply text-green-600 dark:text-green-400;
}

.hljs-name {
  @apply text-blue-500 dark:text-blue-400;
}

.hljs-string {
  @apply text-red-400 dark:text-red-400;
}

.hljs-attr {
  @apply text-yellow-500 dark:text-yellow-400;
}

.language-javascript .token.operator {
  background: transparent;
}

.language-javascript .token.number, .language-javascript .token.boolean {
  color: #ca5102;
}

.tooltip-arrow-left::before {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  left: 5px;
  top: 50%;
  border: 7px solid transparent;
  border-left: 0;
  border-right: 7px solid rgb(55 65 81 / 90%);
  transform: translate(calc(-100% - 5px), -50%);
}

.datepicker-input::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
}


